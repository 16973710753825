import React, { useContext } from 'react'
import { Buffer } from 'buffer'
import MUIDataTable from 'mui-datatables'
import {createTheme, ThemeProvider} from "@mui/material/styles"
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import {
  Typography,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  Stack,
  IconButton, Tooltip,
} from '@mui/material'
import Papa from 'papaparse'
import { API } from 'aws-amplify'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto'
import PanToolIcon from '@mui/icons-material/PanTool'
import WifiIcon from '@mui/icons-material/Wifi'
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const style = {
  color: '#ffffff',
  fontSize: '30px',
  padding: '5px',
  borderRadius: '100%',
}

const StatusView = (status) => {
  switch (status.type) {
    case 1:
    case 6:
    case 7:
    case 14:
    case 16:
      return (
        <LockOutlinedIcon sx={{ ...style, backgroundColor: '#DB817C' }} />
      )
    case 2:
    case 8:
    case 15:
    case 17:
      return (
        <LockOpenOutlinedIcon
          sx={{ ...style, backgroundColor: '#68C6C8' }}
        />
      )
    case 18:
    case 19:
    case 20:
      return (
        <TouchAppIcon sx={{ ...style, backgroundColor: '#68C6C8' }} />
      )
    default:
      return ''
  }
}

const ManualView = (manual) => {
  switch (manual.type) {
    case 6:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}>
          AUTO
        </Typography>
      )
    case 7:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}>
          手動施錠
        </Typography>
      )
    case 8:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}>
          手動解錠
        </Typography>
      )
    case 18:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}
        >
          Bluetooth Click
        </Typography>
      )
    case 19:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}>
          Wifi Click
        </Typography>
      )
    case 20:
      return (
        <Typography color='info.light'
          sx={{
            fontSize: "14px",
            fontFamily: "'Noto Sans JP', sans-serif",
            color: "#000000",
          }}>
          Web Click
        </Typography>
      )
    default:
      return ''
  }
}

const ViaView = (via) => {
  switch (via.type) {
    case 1:
    case 2:
    case 18:
      return <BluetoothIcon sx={{ color: '#cccccc' }} />
    case 6:
      return <MotionPhotosAutoIcon sx={{ color: '#cccccc' }} />
    case 7:
    case 8:
      return <PanToolIcon sx={{ color: '#cccccc' }} />
    case 14:
    case 15:
    case 19:
      return <WifiIcon sx={{ color: '#cccccc' }} />
    case 16:
    case 17:
    case 20:
      return <ComputerRoundedIcon sx={{ color: '#cccccc' }} />
    default:
      return ''
  }
}

export default function DeviceHistory({
  deviceUUID,
  deviceName,
  deviceHistory,
  getDeviceHistory,
  rowsPerPage,
  setRowsPerPage,
}) {
  const { gStripe } = useContext(GlobalStateContext)
  const theHistory = deviceHistory.filter(
    (history) =>
      history.type === 1 ||
      history.type === 2 ||
      history.type === 6 ||
      history.type === 7 ||
      history.type === 8 ||
      history.type === 14 ||
      history.type === 15 ||
      history.type === 16 ||
      history.type === 17 ||
      history.type === 19 ||
      history.type === 20
  )

  const printCsv = async (data) => {
    const fields = ['状態', '時間', 'ユーザー名', '操作方法']
    var csv = Papa.unparse({ fields, data })
    var blob = new Blob([csv])
    var a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = `${deviceName}履歴.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const downloadDeviceHistory = async () => {
    let data = []
    const res = await API.get('Attendance', `/csv`, {
      queryStringParameters: {
        deviceUUID,
      },
    })
      .then((res) => {
        return res.filter(
          (history) =>
            history.type === 1 ||
            history.type === 2 ||
            history.type === 6 ||
            history.type === 7 ||
            history.type === 8 ||
            history.type === 14 ||
            history.type === 15 ||
            history.type === 16 ||
            history.type === 17 ||
            history.type === 19 ||
            history.type === 20
        )
      })
      .catch((err) => console.log(err))
console.log("当前设备测试",res)

      if (!res){
          return
      }
    for (let i = 0; i < res.length; i++) {
      let status = res[i].type
      let time = res[i].timestamp
      let user = res[i].history_tag
      let via = res[i].type

      switch (status) {
        case 1:
        case 6:
        case 7:
        case 14:
        case 16:
          status = '施錠'
          break
        case 2:
        case 8:
        case 15:
        case 17:
          status = '解錠'
          break
        case 18:
        case 19:
        case 20:
          status = 'bot 施解錠'
          break
        default:
          status = ''
      }

      const csvTimestamp =
        new Date(time).toLocaleString('ja-JP', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        new Date(time).toLocaleTimeString('ja-JP', {
          hour12: true,
        })

      if (user === undefined) {
        user = ''
      }
      switch (via) {
        case 1:
        case 2:
        case 18:
          via = 'Bluetooth'
          break
        case 6:
          via = 'オートロック'
          break
        case 7:
        case 8:
          via = '手動'
          break
        case 14:
        case 15:
        case 19:
          via = 'WiFiモジュール'
          break
        case 16:
        case 17:
        case 20:
          via = 'web API'
          break
        default:
          via = ''
      }

      data.push([status, csvTimestamp, user, via])
    }

    await printCsv(data)
  }

  const customTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                    "&:first-of-type": {
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#f8f8f8",
                    },
                    "&:hover": {
                        cursor: "pointer",
                    },
                },
                footer: {
                  backgroundColor: 'white !important', // TableFooter的背景顏色
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    border: "none"
                },
            },
        },

    },
  })

  return (
    <Stack sx={{margin: "30px 20px 0px"}}>
      <ThemeProvider theme={customTheme}>
        <MUIDataTable

            title={
              <Box sx={{marginTop:'10px'}}>
                <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px" }} >履歴</Typography>
          {/*     <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      flexDirection: "row",
                    }}
                >
                      </Box>
              */}
                <Tooltip title="CSVダウンロード">
                  <IconButton
                      sx={{marginTop:'8px', marginLeft: "-14px"}}
                      onClick={() => {
                        downloadDeviceHistory()
                      }}
                  >
                    <CloudDownloadIcon
                        fontSize="small"
                        sx={{
                          color: "rgba(0, 0, 0, 0.54);",
                        }}
                    />
                  </IconButton>
                </Tooltip>

              </Box>
            }
        /*  title={<Typography variant='h2'>履歴</Typography>}*/
          columns={[
            {
              name: 'type',
              label: '状態',
              options: {
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <StatusView
                      type={theHistory[dataIndex].type}
                      sx={{ alignItems: 'center' }}
                    />
                  )
                },
              },
            },
            {
              name: 'timestamp',
              label: '時間',
              options: {
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "'Noto Sans JP', sans-serif",
                        color: "#000000",
                      }}
                    >
                      {new Date(theHistory[dataIndex].timestamp).toLocaleString(
                        'ja-JP',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        }
                      )}{' '}
                      {new Date(theHistory[dataIndex].timestamp).toLocaleTimeString(
                        'ja-JP',
                        { hour12: true }
                      )}
                    </Typography>
                  )
                },
              },
            },
            {
              name: 'history_tag',
              label: 'ユーザー名',
              options: {
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <>
                      <Typography
                       sx={{
                        fontSize: "14px",
                        fontFamily: "'Noto Sans JP', sans-serif",
                        color: "#000000",
                       }}

                      >
                        {theHistory[dataIndex].history_tag &&
                          Buffer.from(
                            theHistory[dataIndex].history_tag,
                            'base64'
                          ).toString('utf8')}
                      </Typography>
                      <ManualView type={theHistory[dataIndex].type}
                      />
                    </>
                  )
                },
              },
            },
            {
              name: 'type',
              label: '操作方法',
              options: {
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                  return (
                    <ViaView
                      type={theHistory[dataIndex].type}
                    />
                  )
                },
              },
            },
          ]}
          data={theHistory}
          options={{
      /*      downloadOptions: {
              filename: `${deviceName}履歴.csv`,
              separator: ',',
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },*/
        /*   onDownload: (buildHead, buildBody, columns, data) => {
              downloadDeviceHistory()
              return false
            },*/
            setTableProps: () => {
              return { size: 'small' }
            },
            filter: false,
            search: false,
            selectableRows: 'none',
            pagination: true,
            rowsPerPage: [10],
            elevation: 0,
            toolbar:false,
            download:false,
            viewColumns:false,
            rowsExpanded: false,
            downloadCsv:false,
            print:false,
            textLabels: {
              body: {
                noMatch: '検索結果は０件です',
                toolTip: 'ソート',
                columnHeaderTooltip: (column) => `ソート ${column.label}`,
              },
              pagination: {
                next: 'Next Page',
                previous: 'Previous Page',
                rowsPerPage: '表示数',
                displayRows: '/',
              },
          /*    toolbar: {
                downloadCsv:'CSV'
                print: '印刷する',
                viewColumns: '列を表示',
              },*/
              viewColumns: {
                title: '列を表示',
                titleAria: 'Show/Hide Table Columns',
              },
            },
            customFooter: (
              count,
              page,
              rowsPerPage,
              changeRowsPerPage,
              changePage,
              textLabels
            ) => {
              return (
                <TableFooter sx={{ p: 0, m: 0 }}>
                  <TableRow sx={{ p: 0, m: 0 }}>
                    <TableCell sx={{ p: 0, m: 0 }}>
                      <TablePagination
                        sx={{ p: 0, m: 0 }}
                        component='div'
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={textLabels.rowsPerPage}
                        labelDisplayedRows={() => ``}
                        rowsPerPageOptions={[10, 20, 100]}
                        onPageChange={(e, page) => {
                          changePage(page)
                        }}
                        onRowsPerPageChange={(e) => {
                          setRowsPerPage(e.target.value)
                          changeRowsPerPage(e.target.value)
                        }}
                        ActionsComponent={({ onPageChange }) => {
                          return (
                            <Box sx={{ display: 'flex' }}>
                              <IconButton
                                onClick={(event) => {
                                  onPageChange(event, page - 1)
                                }}
                                disabled={page === 0}
                              >
                                <KeyboardArrowLeft />
                              </IconButton>
                              <IconButton
                                onClick={(event) => {
                                  getDeviceHistory(deviceUUID, gStripe.customer.id)
                                  onPageChange(event, page + 1)
                                }}
                                disabled={
                                  page >= Math.ceil(count / rowsPerPage) - 1
                                }
                              >
                                <KeyboardArrowRight />
                              </IconButton>
                            </Box>
                          )
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )
            },
          }}
        />
      </ThemeProvider>
    </Stack>
  )
}
