import React, { useContext, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import MUIDataTable from 'mui-datatables'
import {createTheme, ThemeProvider} from "@mui/material/styles"
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import { Typography,Box } from '@mui/material'
import {gUtils} from "../utils/gutils";

export default function DeviceUserList({ deviceUUID }) {
  const { gStripe } = useContext(GlobalStateContext)
  const [userInfo, setUserInfo] = useState([])

  const getDeviceUser = (deviceUUID, companyID) => {
   //   console.log("getDeviceUser",deviceUUID,companyID)

    API.get('Attendance', `/device/members`, {
      queryStringParameters: { deviceUUID, companyID },
    })
      .then((res) => {

          let   d=res.map(item=>{
              let  data='常時利用'

              if (item.keyLevel===2){
                  if (item.startAt && item.endAt) {
                      // Convert UNIX timestamps from seconds to milliseconds by multiplying by 1000
                      data=gUtils.getStartTimeEndTime(item)
                  }
              }




              return {...item,msgdata:data}

          })

        setUserInfo(d)

      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (deviceUUID && gStripe.customer.id) {
      getDeviceUser(deviceUUID, gStripe.customer.id)
    }
  }, [deviceUUID, gStripe.customer.id])

  const customTheme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "none",
                    "&:first-of-type": {
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: "6px",
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#f8f8f8",
                    },
                    "&:hover": {
                        cursor: "pointer",
                    },
                },
            },
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    border: "none"
                },
            },
        },

    },
  })


  return (
    <Box sx={{margin: "0px 20px"}}>
      <ThemeProvider theme={customTheme}>
        {userInfo.length > 0 && (
          <MUIDataTable
            title={
              <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px", marginBottom: "-10px"}}>
                本システム内合鍵所有ユーザー 一覧
              </Typography>
            }
            data={userInfo}
            columns={[
              {
                name: 'employeeName',
                label: 'ユーザー名',
                options: {
                  filter: true,
                  filterType: 'textField',
                  sort: true,
                },
              },
              {
                name: 'employeeEmail',
                label: 'メールアドレス',
                options: {
                  filter: true,
                  filterType: 'textField',
                  sort: true,
                },
              },
              {
                name: 'keyLevel',
                label: '合鍵権限',
                options: {
                  sort: true,
                  sortDirection: 'asc',
                  customBodyRender: (value) => {
                    return (
                      <>
                        {value === 0
                          ? 'オーナー鍵'
                          : value === 1
                          ? 'マネージャー鍵'
                          : 'ゲスト鍵'}
                      </>
                    )
                  },
                },
              },
              {
                name: 'msgdata',
                label: '有効時間',
                options: {
                    customBodyRender: (value) => {
                      return (
                          <Typography sx={{fontSize: "14px"}}>{value}</Typography>
                      )

                  },
                },
              },
            ]}
            options={{
              pagination: false,
              setTableProps: () => {
                return {
                  size: 'small',
                }
              },
              setRowProps: (row, dataIndex) => {
                return {
                  sx: {
                    '> td > div': {
                      color:
                        userInfo[dataIndex].endAt <
                        Math.floor(new Date().getTime() / 1000)
                          ? '#cccccc'
                          : 'black',
                    },
                  },
                }
              },
              rowStyle: {
                fontSize: '16px',
              },
              filter: false,
              search: false,
              print: false,
              download: false,
              viewColumns: false,
              sortOrder: {
                name: 'keyLevel',
                direction: 'asc',
              },
              elevation: 0,
              selectableRows: 'none',
              rowsPerPage: [10],
              rowsPerPageOptions: [10, 20, 100],
              textLabels: {
                body: {
                  noMatch: '検索結果は０件です',
                  toolTip: 'ソート',
                  columnHeaderTooltip: (column) => `ソート ${column.label}`,
                },
                pagination: {
                  next: 'Next Page',
                  previous: 'Previous Page',
                  rowsPerPage: '表示数',
                  displayRows: '/',
                },
                toolbar: {
                  search: '検索',
                  downloadCsv: 'CSV',
                  print: '印刷する',
                  viewColumns: '列を表示',
                  filterTable: 'フィルター',
                },
                viewColumns: {
                  title: '列を表示',
                  titleAria: 'Show/Hide Table Columns',
                },
                filter: {
                  all: '全て',
                  title: 'フィルター',
                  reset: 'リセット',
                },
                selectedRows: {
                  text: '選択済み',
                },
              },
            }}
          />
        )}
      </ThemeProvider>
    </Box>
  )
}
