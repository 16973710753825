import React, {useEffect, useState, useContext} from "react"
import {API} from "aws-amplify"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import Layout from "../../layouts"
import Hider from "../../components/utils/Hider"
import DeviceHistory from "../../components/attendance/deviceHistory"
import DeviceUserList from "../../components/attendance/DeviceUserList"
import {createTheme, ThemeProvider} from "@mui/material/styles"
import AccessCheck from "../../components/attendance/accessCheck"
import {
    Grid,
    Box,
    CircularProgress,
    Typography,
    Button,
    IconButton,
    FormLabel,
    TextField,
    CardHeader, CardContent, List, ListItem
} from "@mui/material"

import MUIDataTable from "mui-datatables"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {cfputils} from "../../components/utils/cfputils";
import {gConfig} from "../../chatai/utils/config";

export default function DeviceDetails({location}) {
    const {gStripe,  gCfp, gCfpDevice} = useContext(GlobalStateContext)


    const initDevice = {
        deviceName: "",
        CHSesame2Status: "",
        batteryPercentage: 0,
        isConnectWifi: false,
        register_time: 0,
    }
    const {state = {}} = location
    const [isPending, setIsPending] = useState(false)
    const [deviceUUID, setDeviceUUID] = useState('')

    const [deviceHistory, setDeviceHistory] = useState([])
    const [timestamp, setTimestamp] = useState(undefined)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [bindData, setBindData] = useState([])
    const [mdetails, setmdetails] = useState({})
    const [mtitle, setMTitle] = useState('')
    const [edit, setEdit] = useState(false)
    const [editInfo, setEditInfo] = useState({deviceName: ''})
    const title = {width: "180px", fontWeight: "bold",}
    const infoItem = {display: "flex", alignItems: "center"}



    useEffect(() => {
   console.log("devicedetails ",state)

        if (state) {
            //setDeviceName(state.data.deviceName)
            setMTitle(state.data.deviceName)
            setDeviceUUID(state.data.deviceUUID)
            setEditInfo({deviceName: state.data.deviceName})
            setmdetails({
                デバイスUUID: state.data.deviceUUID,
                電池残量: `${state.data.battery || "100"}%`,
                モデル: state.data.deviceModel===gConfig.model.bot_2?"ssmbot_2":state.data.deviceModel,
             //   登録日時: cfputils.timestampToTime(state.data.ts),
                登録日時: cfputils.timestampToTime(state.data.registerBizTime),
            })
            getDeviceHistory(state.data.deviceUUID, gStripe.customer.id)
        }

    }, [state])


    const getDeviceHistory = (deviceUUID, companyID) => {
     //   console.log("timestamp", timestamp, companyID)
        API.get("Attendance", `/device/history`, {
            queryStringParameters: {
                deviceUUID,
                timestamp,
                companyID,
            },
        })
            .then((res) => {
             //   console.log("获取历史记录", res)
                setDeviceHistory((oldArr) => [...oldArr, ...res])
                setTimestamp(res[res.length - 1].timestamp || 0)
                setIsPending(false)
            })
            .catch((err) => {
            //    console.log("e", err)
                setIsPending(false)
            })
    }
    useEffect(() => {

        setBindData(gCfpDevice.bindData)

    }, [gCfpDevice.bindData]);



    useEffect(async () => {

        if (deviceUUID) {

            await gCfpDevice.getBindDevice(deviceUUID)

        }

    }, [deviceUUID])


    const customTheme = createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        border: "none",
                        "&:first-of-type": {
                            borderTopLeftRadius: "6px",
                            borderBottomLeftRadius: "6px",
                        },
                        "&:last-of-type": {
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                        },
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:nth-of-type(odd)": {
                            backgroundColor: "#f8f8f8",
                        },
                        "&:hover": {
                            cursor: "pointer",
                        },
                    },
                },
            },
            MUIDataTableBodyRow: {
                styleOverrides: {
                    root: {
                        border: "none"
                    },
                },
            },

        },
      })

    return (
        <Layout location={location}>
            <AccessCheck access="デバイス">


                <Hider show={isPending}>
                    <CircularProgress
                        sx={{
                            position: "fixed",
                            margin: "auto auto",
                            zIndex: "101",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                        color="primary"
                    />
                    <Box>
                        <Grid item xs={12} sx={{ margin: "20px 0px"}}>
                            <Hider show={edit}>
                                <Box sx={{display: "flex", alignItems: "flex-start"}}>
                                    <IconButton
                                        size="small"
                                        sx={{mt: "3px"}}
                                        onClick={() => {
                                            window.history.back()
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon/>
                                    </IconButton>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <FormLabel
                                            sx={{color: "black", mr: "43px", fontWeight: "bold"}}
                                            required
                                        >
                                            デバイス名
                                        </FormLabel>
                                        <TextField
                                            sx={{minWidth: "350px"}}
                                            size="small"
                                            required
                                            value={editInfo.deviceName}
                                            onChange={(e) => {
                                                setEditInfo({

                                                    deviceName: e.target.value,
                                                })
                                                //  state.title = e.target.value
                                            }}
                                        />

                                    </Box>
                                    <Box
                                        sx={{
                                            mt: "10px",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Button
                                            sx={{mr: 1}}
                                            size="small"
                                            onClick={() => {
                                                setEdit(false)

                                            }}
                                        >
                                            キャンセル
                                        </Button>
                                        <Button
                                            disableElevation
                                            size="small"
                                            variant="outlined"

                                            onClick={() => {
                                         //       console.log("修改设备名称")
                                                setEdit(false)
                                                gCfp.cfpModifyCfpDevicesName(
                                                    state.data.deviceUUID,
                                                    editInfo,
                                                    () => {
                                                        setMTitle(editInfo.deviceName)
                                                    }
                                                )

                                                /*    gCfp.cfpModifyCfpDevicesName(
                                                        touchId,
                                                        editInfo,
                                                        setEdit(false)
                                                    )*/
                                            }}
                                        >
                                            確定
                                        </Button>
                                    </Box>
                                </Box>
                                <Box>
                                    <CardHeader
                                        title={
                                            <Box sx={{display: "flex", alignItems: "center"}}>
                                                <IconButton onClick={() => {
                                                    window.history.back()
                                                }}>
                                                    <KeyboardArrowLeftIcon/>
                                                </IconButton>
                                                <Typography variant="h2" sx={{ml: "9px"}}>
                                                    {mtitle}
                                                </Typography>
                                                <IconButton
                                                    sx={{ml: "2px"}}
                                                    size="small"
                                                    onClick={() => {
                                                        setEdit(true)
                                                    }}
                                                >
                                                    <EditRoundedIcon
                                                        fontSize="inherit"
                                                        sx={{color: "primary.main", fontSize: "14px"}}
                                                    />
                                                </IconButton>
                                            </Box>
                                        }
                                    />

                                    <CardContent>
                                        <List
                                            disablePadding
                                            sx={{
                                                "> .css-1samsxy-MuiListItem-root": {
                                                    padding: "0px",
                                                },
                                            }}
                                        >
                                            {Object.entries(mdetails).map(([key, value], index) => (
                                                <ListItem key={index} sx={{...infoItem}}>
                                                    <Typography sx={{...title}}>{key}</Typography>
                                                    {value}
                                                    {key === "社员" && (
                                                        <Button
                                                            sx={{marginLeft: "50px"}}
                                                            variant="outlined"
                                                            onClick={() => {

                                                                //   setisBindMember(true)
                                                            }}
                                                        >
                                                            绑定社员
                                                        </Button>
                                                    )}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Box>
                            </Hider>

                            {/*       <DeviceInfo
                name={deviceName}
                deviceUUID={deviceUUID}
                deviceInfo={deviceInfo}
                getDeviceInfo={getDeviceInfo}
                editDeviceInfo={editDeviceInfo}
                setEditDeviceInfo={setEditDeviceInfo}
                gIot={gIot}
                isSecretKey={isSecretKey}
              />*/}
                        </Grid>
                        {/* <Button onClick={handleClick}>取得認證機器列表</Button> */}
                        <Grid item xs={12}>
                            {bindData.length > 0 && (
                                <Box sx={{margin: "0px 20px 30px"}}>
                                    <ThemeProvider theme={customTheme}>
                                        <MUIDataTable
                                            data={bindData}
                                            title={
                                                <Typography sx={{ fontSize: "22px", fontFamily: "'Noto Sans JP', sans-serif", fontWeight: "bold", marginLeft: "-10px", marginBottom: "-10px"}}>
                                                    連携済み認証機器
                                                </Typography>
                                            }
                                            columns={[
                                                {
                                                    name: "deviceName",
                                                    label: "デバイス名",
                                                },
                                                {
                                                    name: "deviceModel",
                                                    label: "モデル",
                                                },
                                            ]}
                                            options={{
                                                elevation: 0,
                                                print: false,
                                                download: false,
                                                hover: false,
                                                filter: false,
                                                search: false,
                                                viewColumns: false,
                                                pagination: false,
                                                selectableRows: false,
                                                selectToolbarPlacement: "none",
                                                setTableProps: () => {
                                                    return {fullWidth: true, size: "small"}
                                                },
                                            }}
                                        />
                                    </ThemeProvider>
                                </Box>
                            )}
                            <DeviceUserList
                                deviceUUID={deviceUUID}
                                deviceName={initDevice}
                            />
                            {deviceHistory.length > 0 && (
                            <Box sx={{marginTop: "20px"}}>
                                <DeviceHistory
                                    deviceUUID={deviceUUID}
                                    deviceName={initDevice}
                                    deviceHistory={deviceHistory}
                                    getDeviceHistory={getDeviceHistory}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Box>
                            )}
                        </Grid>
                    </Box>
                </Hider>
            </AccessCheck>
        </Layout>
    )
}
